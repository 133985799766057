<template>
<div>
	<v-container fluid style="padding:0px; margin-bottom: -128px;" v-if="isSmallScreen == 'Desktop'"  class="desktop-size">
		<v-row no-gutters class="text-center" style="margin-top:-128px;">
			<v-col cols="12" :style="'background-image: url('+require('../assets/Contact/ContactUs_Desktop_01.jpg')+'); ' + `height:${heightSize}px`" class="header backGroundImage" >
				<h1 class="lusciousLifestylesScriptFont contactUsLabel">{{contactUsLabel[languageId]}}</h1>
			</v-col>
        </v-row>
		<v-row no-gutters class="text-justify mt-16 mb-16">
			<v-col cols="2"></v-col>
			<v-col cols="4" style="padding:0px 20px;">
				<h2 class="lusciousLifestylesScriptFont h2 colorGreen">{{helloLabel[languageId]}}</h2>
				<hr style="margin-left:-180px; margin-bottom: -25px; margin-top:25px; width:150px">
				<h3 class="poppinsSemiboldFont h3 colorGreen">{{subtitleLabel[languageId]}}</h3>
				<div class="mb-4 mt-4" > 
					<a target="_blank" href="mailto:contactus@karlusmorales.com" class="colorOldBrown poppinsboldFont p">
						<v-avatar :color="greenColor" size="27" >
							<v-icon small color="white">
								mdi-email-outline
							</v-icon>
						</v-avatar>
						contactus@karlusmorales.com
					</a>
				</div>
				<div class="mb-4" >
					<a target="_blank" href="tel:+573043763052"  class="colorOldBrown poppinsboldFont p">
						<v-avatar :color="greenColor" size="27" >
							<v-icon small color="white">
								mdi-phone
							</v-icon>
						</v-avatar>
						+57 304 3763052
					</a>
				</div>
				<div class="mb-4" >
					<a class="mr-2" target="_blank" href="https://www.instagram.com/karlusmorales/">
						<v-icon :color="brownColor2">
							mdi-instagram
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://www.facebook.com/KarlusMoraless">
						<v-icon :color="brownColor2">
							mdi-facebook
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://co.pinterest.com/karlusmorales/">
						<v-icon :color="brownColor2">
							mdi-pinterest
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052">
						<v-icon :color="brownColor2">
							mdi-whatsapp
						</v-icon>
					</a>
				</div>
			</v-col>
			<v-col cols="4" style="padding:0px 20px;">
				<h2 class="lusciousLifestylesScriptFont h2 colorGreen">{{writeUsLabel[languageId]}}</h2>
				<v-form 
					ref="form"
					lazy-validation
				>
				<v-row no-gutters>
					<v-col cols="6" style="padding-right:5px">
						<v-text-field
							class="poppinsLightFont"
							v-model="name"
							:label="nameLabel[languageId]"
							required
							:rules="nameRules[languageId]"
						></v-text-field>
						<v-text-field
							class="poppinsLightFont"
							v-model="phone"
							:label="phoneLabel[languageId]"
						></v-text-field>
					</v-col>
					<v-col cols="6" style="padding-left:5px">
						<v-text-field
							class="poppinsLightFont"
							v-model="email"
							:label="emailLabel[languageId]"
							required
							:rules="emailRules[languageId]"
						></v-text-field>
						<v-text-field
							class="poppinsLightFont"
							v-model="subject"
							:label="subjectLabel[languageId]"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-textarea
							class="poppinsLightFont"
							:label="messageLabel[languageId]"
							v-model="message"
							:hint="messageHintLabel[languageId]"
							:rules="messageRules[languageId]"
							rows=3
							counter
						>
						</v-textarea>
						<v-btn
							class="mt-4 pl-6 pr-6"
							rounded
							small
							@click="submit"
							:color="brownColor"
							style="color:white; font-family:'Poppins-Bold'"
							elevation="0"
							:disabled="!allowSendRequest"
						>
							{{sendButtonLabel[languageId]}}
						</v-btn>
					</v-col>
				</v-row>
				</v-form>
			</v-col>
			<v-col cols="2"></v-col>
        </v-row>
		<footerComponent :backgroundImage="footerImage"></footerComponent>
	</v-container>


	<v-container fluid style="padding:0px; margin-bottom: -128px;" v-if="isSmallScreen == 'Tablet'" class="tablet-size">
		<v-row no-gutters class="text-center"  style="margin-top:-128px;">
			<v-col cols="12" :style="'background-image: url('+require('../assets/Contact/ContactUs_Desktop_01.jpg')+'); ' + `height:${heightSize}px`" class="header-tablet backGroundImage" >
				<h1 class="lusciousLifestylesScriptFont contactUsLabel-tablet" style="font-size:174px;">{{contactUsLabel[languageId]}}</h1>
			</v-col>
        </v-row>
		<v-row no-gutters class="text-justify mt-16 mb-16">
			<v-col cols="1"></v-col>
			<v-col cols="10" style="padding:0px 20px;">
				<h2 class="lusciousLifestylesScriptFont h2-tablet colorGreen" style="font-size:81px;">{{helloLabel[languageId]}}</h2>
				<hr style="margin-left:-60px; margin-bottom: -32px; margin-top:30px; width:50px; background-color:#414A25; border-color:#414A25;">
				<h3 class="poppinsSemiboldFont h3-tablet colorGreen" style="font-size:57px; line-height:60px;">{{subtitleLabel[languageId]}}</h3>
				<div class="mb-4 mt-4" > 
					<a target="_blank" href="mailto:contactus@karlusmorales.com" class="colorOldBrown poppinsboldFont p-tablet" style="font-size:31px;">
						<v-avatar :color="greenColor" size="35" >
							<v-icon color="white">
								mdi-email-outline
							</v-icon>
						</v-avatar>
						contactus@karlusmorales.com
					</a>
				</div>
				<div class="mb-4" >
					<a target="_blank" href="tel:+573043763052"  class="colorOldBrown poppinsboldFont p-tablet" style="font-size:31px;">
						<v-avatar :color="greenColor" size="35" >
							<v-icon color="white">
								mdi-phone
							</v-icon>
						</v-avatar>
						+57 304 3763052
					</a>
				</div>
				<div class="mb-4" >
					<a class="mr-2" target="_blank" href="https://www.instagram.com/karlusmorales/" style="font-size:31px;">
						<v-icon large :color="brownColor2">
							mdi-instagram
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://www.facebook.com/KarlusMoraless" style="font-size:31px;">
						<v-icon  large :color="brownColor2">
							mdi-facebook
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://co.pinterest.com/karlusmorales/" style="font-size:31px;">
						<v-icon large :color="brownColor2">
							mdi-pinterest
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052" style="font-size:31px;">
						<v-icon large :color="brownColor2">
							mdi-whatsapp
						</v-icon>
					</a>
				</div>

				<h2 class="lusciousLifestylesScriptFont h2-tablet colorGreen mt-10" style="font-size:99px;">{{writeUsLabel[languageId]}}</h2>
				<v-form 
					ref="form"
					lazy-validation
				>
				<v-row no-gutters>
					<v-col cols="6" style="padding-right:5px">
						<v-text-field
							class="poppinsLightFont"
							v-model="name"
							:label="nameLabel[languageId]"
							required
							:rules="nameRules[languageId]"
						></v-text-field>
						<v-text-field
							class="poppinsLightFont"
							v-model="phone"
							:label="phoneLabel[languageId]"
						></v-text-field>
					</v-col>
					<v-col cols="6" style="padding-left:5px">
						<v-text-field
							class="poppinsLightFont"
							v-model="email"
							:label="emailLabel[languageId]"
							required
							:rules="emailRules[languageId]"
						></v-text-field>
						<v-text-field
							class="poppinsLightFont"
							v-model="subject"
							:label="subjectLabel[languageId]"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-textarea
							class="poppinsLightFont"
							:label="messageLabel[languageId]"
							v-model="message"
							:hint="messageHintLabel[languageId]"
							:rules="messageRules[languageId]"
							rows=3
							counter
						>
						</v-textarea>
						<v-btn
							class="mt-4 pl-6 pr-6"
							x-large
							@click="submit"
							:color="brownColor"
							style="color:white; font-family:'Poppins-Bold'; font-size:29px;"
							elevation="0"
							:disabled="!allowSendRequest"
						>
							{{sendButtonLabel[languageId]}}
						</v-btn>
					</v-col>
				</v-row>
				</v-form>
			</v-col>
			<v-col cols="1"></v-col>
        </v-row>
		<footerComponent :backgroundImage="footerImage"></footerComponent>
	</v-container>


	<v-container fluid style="padding:0px; margin-bottom: -128px;" v-if="isSmallScreen == 'Mobile'" class="mobile-size">
		<v-row no-gutters class="text-center" >
			<v-col cols="12" :style="'background-image: url('+require('../assets/Contact/ContactUs_Desktop_01.jpg')+'); ' + `height:${heightSize}px`" class="header-mobile backGroundImage" >
				<h1 class="lusciousLifestylesScriptFont contactUsLabel-mobile">{{contactUsLabel[languageId]}}</h1>
			</v-col>
        </v-row>
		<v-row no-gutters class="text-justify mt-16 mb-16">
			<v-col cols="1"></v-col>
			<v-col cols="10" style="padding:0px 20px;">
				<h2 class="lusciousLifestylesScriptFont h2-mobile colorGreen" style="font-size:46px;">{{helloLabel[languageId]}}</h2>
				<hr style="margin-left:-30px; margin-bottom: -12px; margin-top:10px; width:20px;">
				<h3 class="poppinsSemiboldFont h3-mobile colorGreen" style="font-size:30px; line-height:40px; text-align: left !important;">{{subtitleLabel[languageId]}}</h3>
				<div class="mb-4 mt-4" > 
					<a target="_blank" href="mailto:contactus@karlusmorales.com" class="colorOldBrown poppinsboldFont p-mobile" style="font-size:13px; word-break: break-word;">
						<v-avatar :color="greenColor" size="27" >
							<v-icon small color="white">
								mdi-email-outline
							</v-icon>
						</v-avatar>
						contactus@karlusmorales.com
					</a>
				</div>
				<div class="mb-4" >
					<a target="_blank" href="tel:+573043763052"  class="colorOldBrown poppinsboldFont p-mobile" style="font-size:14px; word-break: break-word;">
						<v-avatar :color="greenColor" size="27" >
							<v-icon small color="white">
								mdi-phone
							</v-icon>
						</v-avatar>
						+57 304 3763052
					</a>
				</div>
				<div class="mb-4" >
					<a class="mr-2" target="_blank" href="https://www.instagram.com/karlusmorales/">
						<v-icon :color="brownColor2">
							mdi-instagram
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://www.facebook.com/KarlusMoraless">
						<v-icon :color="brownColor2">
							mdi-facebook
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://co.pinterest.com/karlusmorales/">
						<v-icon :color="brownColor2">
							mdi-pinterest
						</v-icon>
					</a>

					<a class="mr-2" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052">
						<v-icon :color="brownColor2">
							mdi-whatsapp
						</v-icon>
					</a>
				</div>

				<h2 class="lusciousLifestylesScriptFont h2-mobile colorGreen" style="font-size:50px;">{{writeUsLabel[languageId]}}</h2>
				<v-form 
					ref="form"
					lazy-validation
				>
				<v-row no-gutters>
					<v-col cols="12" style="padding-right:5px">
						<v-text-field
							class="poppinsLightFont"
							v-model="name"
							:label="nameLabel[languageId]"
							required
							:rules="nameRules[languageId]"
						></v-text-field>
						<v-text-field
							class="poppinsLightFont"
							v-model="email"
							:label="emailLabel[languageId]"
							required
							:rules="emailRules[languageId]"
						></v-text-field>
					</v-col>
					<v-col cols="12" style="padding-left:5px">
						<v-text-field
							class="poppinsLightFont"
							v-model="phone"
							:label="phoneLabel[languageId]"
						></v-text-field>
						<v-text-field
							class="poppinsLightFont"
							v-model="subject"
							:label="subjectLabel[languageId]"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-textarea
							class="poppinsLightFont"
							:label="messageLabel[languageId]"
							v-model="message"
							:hint="messageHintLabel[languageId]"
							:rules="messageRules[languageId]"
							rows=3
							counter
						>
						</v-textarea>
						<v-btn
							class="mt-4 pl-6 pr-6"
							@click="submit"
							:color="brownColor"
							style="color:white; font-family:'Poppins-Bold'"
							elevation="0"
							:disabled="!allowSendRequest"
						>
							{{sendButtonLabel[languageId]}}
						</v-btn>
					</v-col>
				</v-row>
				</v-form>
			</v-col>
			<v-col cols="1"></v-col>
        </v-row>
		<footerComponent :backgroundImage="footerImage"></footerComponent>
	</v-container>
</div>
</template>

<script>
import footerComponent from './components/Footer'
import axios from "axios";
import { mapState } from "vuex";
const baseURL = process.env.VUE_APP_BASE_URL_API;
const captchaKey = process.env.VUE_APP_CAPTCHA_KEY;
	export default {
		name: 'Contact',
		components: { footerComponent },
		data: () => ({
			pageTitle:['Contacta a Karlus Morales y adquiere sus fotografías hechas cuadros','Contact Karlus Morales and get his photographs turned into paintings'],
			pageMetaTitle:['Contacto de Karlus Morales | Fotografía de Naturaleza','Contact information for Karlus Morales | Nature Photography'],
			pageDescription:['¿Quieres conectar con la naturaleza y tenerla en tus paredes? Contacta a Karlus Morales y adquiere sus fotografías hechas cuadros','Do you want to connect with nature and have it on your walls? Contact Karlus Morales and get his photographs turned into paintings'],
            pageKeywords:['Fotógrafo Karlus Morales, fotografía, cuadros, decoración, naturaleza, arte, venta de fotografías online','Photographer Karlus Morales, photography, paintings, decoration, nature, art, online sale of photographs'],
            metaImage:'',
			metaImageID:85,
			requiresReload:false,
			footerImage:require('../assets/Contact/ContactUs_Desktop_03.jpg'),
			greenColor:'#414A25',
			brownColor:'#BEA67C',
			brownColor2:"#483729",
			brownColorFooter: '#AA7755',
            contactUsLabel:["Contáctanos", "Contact"],
			helloLabel:["Hola","Hello"],
			subtitleLabel:["Esperamos escuchar de ti pronto. Contactanos y di Hola!","We are looking forward to hearing from you. Contact us and say Hello!"],
			writeUsLabel:["Escribenos...","Write us..."],
			nameLabel:["Nombre","Name"],
			emailLabel:["Email","Email"],
			phoneLabel:["Teléfono","Phone"],
			subjectLabel:["Asunto","Subject"],
			messageLabel:["Mensaje","Message"],
			messageHintLabel:["Escribe tu mensaje","Write your message"],
			sendButtonLabel:["Enviar","Send"],

			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
			name:"",
			email:"",
			phone:"",
			subject:"",
			message:"",
			nameRules: [
				[
					v => !!v || 'El nombre es necesario.',
					// v => /^[a-zA-Z]+$/.test(v) || 'El nombre solo debe contener letras.',
				],
				[
					v => !!v || 'The field Name is required.',
					// v => /^[a-zA-Z]+$/.test(v) || 'Name requires only letters.',
				]
			],
			emailRules: [
				[
					v => !!v || 'El email es necesario.',
					// v => /.+@.+\..+/.test(v) || 'El email debe tener un formato válido.',
					v => /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'El email debe tener un formato válido.',
				],
				[
					v => !!v || 'The field Email is required.',
					// v => /.+@.+\..+/.test(v) || 'Email format required.',
					v => /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Email format not valid.',
				]
			],
			messageRules: [
				[
					v => !!v || 'El mensaje es necesario',
					v => v.length <= 500 || 'Máximo 500 characteres'
				],
				[
					v => !!v || 'The field Message is required.',
					v => v.length <= 500 || '500 characters maximum allowed'
				]
			],

			allowSendRequest:true,
		}),
		computed:{
            ...mapState("shoppingCart", 
				[
					"languageId", "privacyPolicyLabel", "termsOfUseLabel", "homeButtonLabel", "artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel","images","artPiecesOk",
				]),
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
			heightSize(){
				var windowWidth = window.innerWidth;
				var height = window.innerHeight;
				if(windowWidth < 320)
					height = 300
				if(windowWidth >= 320 && windowWidth <= 640)
					height = 320
				if(windowWidth > 640 && windowWidth < 1024)
					height = 320
				if(windowWidth >= 1024 && windowWidth <= 1440)
					height = 450
				if(windowWidth > 1440 && windowWidth < 1920)
					height = 450
				if(windowWidth >= 1920)
					height = 635
				return height
			},
		},
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'index, follow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
		async mounted () {
			if(this.artPiecesOk){
				this.metaImage = this.selectMetaImage(this.metaImageID);
			} else {
				this.requiresReload = true;
			}
            await this.loadRecaptcha(document, "script", "google-recaptcha");
		},
		beforeDestroy(){
			document.getElementsByClassName("grecaptcha-badge")[0].style.display="none";
		},
		methods: {
            async loadRecaptcha(d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
					d.getElementsByClassName("grecaptcha-badge")[0].style.display="block";
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://www.google.com/recaptcha/api.js?render="+captchaKey;
                fjs.parentNode.insertBefore(js, fjs);
            },

			submit(){
				this.$refs.form.validate();
				if(!this.$refs.form.validate()) return;
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(captchaKey, {action: 'submit'})
                        .then((token) => {
							var form = {
								name: this.name,
								email: this.email,
								phone: this.phone,
								subject: this.subject,
								message: this.message,
								captcha: token
							}
                            this.sendRequest(form);
                        });
                })
			},

			sendRequest(form){
				this.allowSendRequest = false;
                axios.post(`${baseURL}ContactUs`, form)
                    .then(res => {
                        if(res.status == 200){
							alert("Thank you, your message was delivered.");
                        }
						this.allowSendRequest = true;
                    })
                    .catch(error => { 
						this.allowSendRequest = true;
                        console.log('sendRequest',error);
                    });
			},
			getImageById(idImage){
				if(this.images != null){
					let image = this.images.find(x=>x.id == idImage)
					return image.base64
				}
			},
			selectMetaImage(idImage){
				idImage = idImage!=null?idImage:this.metaImageID;
				let mImage = this.getImageById(idImage)
				if(mImage != null){
					return window.location.origin + require(`../assets/${mImage}`)
				}
			},
		},
		updated(){
			if(this.requiresReload && this.artPiecesOk){
				this.metaImage = this.selectMetaImage(this.metaImageID);
				this.requiresReload = false;
			}
		}
	}
</script>

<style scoped>
a{
	text-decoration:none;
}
.backGroundImage{
	width:100%; 
	background-size:cover; 
}
.header{
	background-position-y:bottom; 
	height:635px; 
	display:flex; 
	align-items: flex-end; 
	justify-content: center;
}
.header-tablet{
	background-position-y:bottom;
	background-position-x:center; 
	height:350px; 
	display:flex; 
	align-items: flex-end; 
	justify-content: center;
}
.header-mobile{
	background-position-y:bottom;
	background-position-x:center; 
	height:300px; 
	display:flex; 
	align-items: flex-end; 
	justify-content: center;
}
.contactUsLabel{
	color: #FEFEFE; 
	padding-bottom:100px; 
	font-size:185px; 
	margin-left: -80px;
}
.contactUsLabel-tablet{
	color: #FEFEFE; 
	font-size:100px; 
	padding-bottom:80px; 
}
.contactUsLabel-mobile{
	color: #FEFEFE; 
	font-size:100px; 
	padding-bottom:80px; 
}
.h2{
	font-size:48px;
}
.h2-tablet{
	font-size:30px;
}
.h2-mobile{
	font-size:30px;
}
.h3{
	font-size:37px; 
	text-transform: uppercase;
}
.h3-tablet{
	font-size:16px; 
	text-transform: uppercase;
}
.h3-mobile{
	font-size:16px; 
	text-transform: uppercase;
}
.p{
	font-size:18px;
}
.p-tablet{
	font-size:12px;
}
.p-mobile{
	font-size:12px;
}
.buttons{
	font-size:10px;
	text-transform: uppercase;
	color:white;
	width:90px;;
	margin: 0px 5px;
}
.buttons-tablet{
	font-size:10px;
	text-transform: uppercase;
	color:white;
	width:90px;;
	margin: 0px 5px;
}
.buttons-mobile{
	font-size:10px;
	text-transform: uppercase;
	color:white;
	width:90px;;
	margin: 0px 5px;
}
.a{
	color:white;
	font-size:10px;
}
.a-tablet{
	color:white;
	font-size:10px;
}
.a-mobile{
	color:white;
	font-size:10px;
}
</style>