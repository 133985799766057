<template>
    <div>
        <v-footer padless :style="'background-image: url('+backgroundImage+')'" class="footer backGroundImage" v-if="isSmallScreen == 'Desktop'" >
            <v-col class="text-center" cols="12" style="padding:0px;">
                <img :src="logoImage" style="margin-top:30px; width:164px;" :alt="languageId==0?'Logo isotipo de Karlus Morales':'Karlus Morales isotype logo'" />
            </v-col>
            <v-col class="text-center" cols="12" style="padding:0px;">
                <a :href="'/'">
                    <button class="poppinsboldFont buttons buttonHover" :class="this.$route.path=='/'?'box':''">
                        {{homeButtonLabel[languageId]}}
                    </button>
                </a>
                <a :href="`/${baseArtPiecesUrl[languageId]}`">
                    <button class="poppinsboldFont buttons buttonHover" :class="this.$route.path.includes(`/${baseArtPiecesUrl[languageId]}`)?'box':''" >
                        {{artPiecesButtonLabel[languageId]}}
                    </button>
                </a>
                <a :href="'/about'">
                    <button class="poppinsboldFont buttons buttonHover" :class="this.$route.path=='/about'?'box':''">
                        {{aboutButtonLabel[languageId]}}
                    </button>
                </a>
                <a :href="'/contact'">
                    <button class="poppinsboldFont buttons buttonHover" :class="this.$route.path=='/contact'?'box':''">
                        {{contactButtonLabel[languageId]}}
                    </button>
                </a>
            </v-col>
            <v-col class="text-center" cols="12"  style="padding:0px;">
                <div>
                    <a target="_blank" href="tel:+573043763052" class="poppinsRegularFont a buttonHover">
                        <v-avatar size="20" >
                            <v-icon small color="white">
                                mdi-phone
                            </v-icon>
                        </v-avatar>
                        +57 304 3763052
                    </a>
                </div>
                <div> 
                    <a target="_blank" href="mailto:contactus@karlusmorales.com" class="poppinsRegularFont a buttonHover">
                        <v-avatar size="20" >
                            <v-icon small color="white">
                                mdi-email-outline
                            </v-icon>
                        </v-avatar>
                        contactus@karlusmorales.com
                    </a>
                </div>
                <div >
                    <a class="mr-2 buttonHover" target="_blank" href="https://www.instagram.com/karlusmorales/">
                        <v-icon color="white">
                            mdi-instagram
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://www.facebook.com/KarlusMoraless">
                        <v-icon color="white">
                            mdi-facebook
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://co.pinterest.com/karlusmorales/">
                        <v-icon color="white">
                            mdi-pinterest
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052">
                        <v-icon color="white">
                            mdi-whatsapp
                        </v-icon>
                    </a>
                    <a class="ml-2 buttonHover" target="_blank" href="https://www.youtube.com/@karlusmorales">
                        <v-icon color="white">
                            mdi-youtube
                        </v-icon>
                    </a>
                </div>
            </v-col>
            <v-col class="text-center" cols="12" style="align-self:flex-end; padding:5px 0px;">
                <v-row no-gutters>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="5" class="text-left a poppinsMediumFont">
                        © {{ new Date().getFullYear() }} KARLUS MORALES
                    </v-col>
                    <v-col cols="5" class="text-right" style="color:white; font-size:12px;">
                        <a style="text-transform: uppercase;" target="_blank"  href="" class="a poppinsboldFont buttonHover">
                            {{privacyPolicyLabel[languageId]}}
                        </a>
                        <span class="pl-2 pr-2 a poppinsboldFont">|</span>
                        <a style="text-transform: uppercase;" target="_blank"  href="" class="a poppinsboldFont buttonHover">
                            {{termsOfUseLabel[languageId]}}
                        </a>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                </v-row>
            </v-col>
        </v-footer>
        <v-footer padless :style="'background-image: url('+backgroundImage+')'" style="background-position-x:17%;" class="footer backGroundImage" v-if="isSmallScreen == 'Tablet'" >
            <v-col class="text-center" cols="12" style="padding:0px;">
                <img :src="logoImage" style="margin-top:30px; width:164px;" :alt="languageId==0?'Logo isotipo de Karlus Morales':'Karlus Morales isotype logo'" />
            </v-col>
            <v-col class="text-center" cols="12" style="padding:0px;">
                <a :href="'/'">
                    <button class="poppinsboldFont buttons-tablet buttonHover" :class="this.$route.path=='/'?'box':''">
                        {{homeButtonLabel[languageId]}}
                    </button>
                </a>
                <a :href="`/${baseArtPiecesUrl[languageId]}`">
                    <button class="poppinsboldFont buttons-tablet buttonHover" :class="this.$route.path.includes(`/${baseArtPiecesUrl[languageId]}`)?'box':''">
                        {{artPiecesButtonLabel[languageId]}}
                    </button>
                </a>
                <a :href="'/about'">
                    <button class="poppinsboldFont buttons-tablet buttonHover" :class="this.$route.path=='/about'?'box':''">
                        {{aboutButtonLabel[languageId]}}
                    </button>
                </a>
                <a :href="'/contact'">
                    <button class="poppinsboldFont buttons-tablet buttonHover" :class="this.$route.path=='/contact'?'box':''">
                        {{contactButtonLabel[languageId]}}
                    </button>
                </a>
            </v-col>
            <v-col class="text-center" cols="12"  style="padding:0px;">
                <div>
                    <a target="_blank" href="tel:+573043763052" class="poppinsRegularFont a-tablet buttonHover">
                        <v-avatar size="20" >
                            <v-icon small color="white">
                                mdi-phone
                            </v-icon>
                        </v-avatar>
                        +57 304 3763052
                    </a>
                </div>
                <div> 
                    <a target="_blank" href="mailto:contactus@karlusmorales.com" class="poppinsRegularFont a-tablet buttonHover">
                        <v-avatar size="20" >
                            <v-icon small color="white">
                                mdi-email-outline
                            </v-icon>
                        </v-avatar>
                        contactus@karlusmorales.com
                    </a>
                </div>
                <div >
                    <a class="mr-2 buttonHover" target="_blank" href="https://www.instagram.com/karlusmorales/">
                        <v-icon color="white">
                            mdi-instagram
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://www.facebook.com/KarlusMoraless">
                        <v-icon color="white">
                            mdi-facebook
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://co.pinterest.com/karlusmorales/">
                        <v-icon color="white">
                            mdi-pinterest
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052">
                        <v-icon color="white">
                            mdi-whatsapp
                        </v-icon>
                    </a>
                    <a class="ml-2 buttonHover" target="_blank" href="https://www.youtube.com/@karlusmorales">
                        <v-icon color="white">
                            mdi-youtube
                        </v-icon>
                    </a>
                </div>
            </v-col>
            <v-col class="text-center" cols="12" style="align-self:flex-end; padding:5px 0px;">
                <v-row no-gutters>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="5" class="text-left a-tablet poppinsMediumFont" style="display:flex; align-items:center;">
                        <div style="display:flex; flex-direction:row; height:100%; align-items:center;">© {{ new Date().getFullYear() }} KARLUS MORALES</div>
                    </v-col>
                    <v-col cols="5" class="text-right" style="color:white;">
                        <a style="text-transform: uppercase;" target="_blank"  href="" class="a-tablet poppinsboldFont buttonHover">
                            {{privacyPolicyLabel[languageId]}}
                        </a>
                        <span class="pl-2 pr-2 a poppinsboldFont">|</span>
                        <a style="text-transform: uppercase;" target="_blank"  href="" class="a-tablet poppinsboldFont buttonHover">
                            {{termsOfUseLabel[languageId]}}
                        </a>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                </v-row>
            </v-col>
        </v-footer>
        <v-footer padless :style="'background-image: url('+backgroundImage+')'" class="footer-mobile backGroundImage" v-if="isSmallScreen == 'Mobile'" >
            <v-col class="text-center" cols="12" style="padding:0px;">
                <img :src="logoImage" style="margin-top:30px; width:100px;" :alt="languageId==0?'Logo isotipo de Karlus Morales':'Karlus Morales isotype logo'"/>
            </v-col>
            <v-col class="text-center" cols="12" style="padding:0px;">
                <a :href="'/'">
                    <button class="poppinsboldFont buttons-mobile buttonHover" :class="this.$route.path=='/'?'box':''">
                        {{homeButtonLabel[languageId]}}
                    </button>
                </a>
            </v-col>
            <v-col class="text-center" cols="12" style="padding:0px;">
                <a :href="`/${baseArtPiecesUrl[languageId]}`">
                    <button class="poppinsboldFont buttons-mobile buttonHover" :class="this.$route.path.includes(`/${baseArtPiecesUrl[languageId]}`)?'box':''">
                        {{artPiecesButtonLabel[languageId]}}
                    </button>
                </a>
            </v-col>
            <v-col class="text-center" cols="12" style="padding:0px;">
                <a :href="'/about'">
                    <button class="poppinsboldFont buttons-mobile buttonHover" :class="this.$route.path=='/about'?'box':''">
                        {{aboutButtonLabel[languageId]}}
                    </button>
                </a>
            </v-col>
            <v-col class="text-center" cols="12" style="padding:0px;">
                <a :href="'/contact'">
                    <button class="poppinsboldFont buttons-mobile buttonHover" :class="this.$route.path=='/contact'?'box':''">
                        {{contactButtonLabel[languageId]}}
                    </button>
                </a>
            </v-col>
            <v-col class="text-center" cols="12"  style="padding:0px;">
                <div>
                    <a target="_blank" href="tel:+573043763052" class="poppinsRegularFont a-mobile buttonHover">
                        <v-avatar size="20" >
                            <v-icon small color="white">
                                mdi-phone
                            </v-icon>
                        </v-avatar>
                        +57 304 3763052
                    </a>
                </div>
                <div> 
                    <a target="_blank" href="mailto:contactus@karlusmorales.com" class="poppinsRegularFont a-mobile buttonHover">
                        <v-avatar size="20" >
                            <v-icon small color="white">
                                mdi-email-outline
                            </v-icon>
                        </v-avatar>
                        contactus@karlusmorales.com
                    </a>
                </div>
                <div class="mt-2 mb-5">
                    <a class="mr-2 buttonHover" target="_blank" href="https://www.instagram.com/karlusmorales/">
                        <v-icon color="white">
                            mdi-instagram
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://www.facebook.com/KarlusMoraless">
                        <v-icon color="white">
                            mdi-facebook
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://co.pinterest.com/karlusmorales/">
                        <v-icon color="white">
                            mdi-pinterest
                        </v-icon>
                    </a>
                    <a class="ml-2 mr-2 buttonHover" target="_blank" href="https://api.whatsapp.com/send?phone=573043763052">
                        <v-icon color="white">
                            mdi-whatsapp
                        </v-icon>
                    </a>
                    <a class="ml-2 buttonHover" target="_blank" href="https://www.youtube.com/@karlusmorales">
                        <v-icon color="white">
                            mdi-youtube
                        </v-icon>
                    </a>
                </div>
            </v-col>
            <v-col class="text-center" cols="12" style="align-self:flex-end; padding:5px 0px;">
                <v-row no-gutters>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="4" class="text-left a-mobile poppinsMediumFont" style="display:flex; align-items:center;">
                        <div style="display:flex; flex-direction:row; height:100%; align-items:center;">© {{ new Date().getFullYear() }} KARLUS MORALES</div>
                    </v-col>
                    <v-col cols="6" class="text-right" style="color:white; font-size:12px;">
                        <a style="text-transform: uppercase;" target="_blank"  href="" class="a-mobile poppinsboldFont buttonHover">
                            {{privacyPolicyLabel[languageId]}}
                        </a>
                        <span class="pl-1 pr-1 a poppinsboldFont">|</span>
                        <a style="text-transform: uppercase;" target="_blank"  href="" class="a-mobile poppinsboldFont buttonHover">
                            {{termsOfUseLabel[languageId]}}
                        </a>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                </v-row>
            </v-col>
        </v-footer>
    </div>
</template>

<script>
import { mapState } from "vuex";
const logoImage = require('../../assets/KarlusMorales_Isotype_Dark.svg')

	export default {
		name: 'About',
        props: ['backgroundImage'],
		data: () => ({
			logoImage:logoImage,

			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
		}),
		computed:{
            ...mapState("shoppingCart", [
                "languageId", "privacyPolicyLabel", "termsOfUseLabel", "homeButtonLabel", "artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel"
                ,"baseArtPiecesUrl",
            ]),
            
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
		},
	}
</script>

<style scoped>
a{
	text-decoration:none;
}
.backGroundImage{
	width:100%; 
	background-size:cover; 
}
.header{
	background-position-y:top; 
	height:1014px; 
	display:flex; 
	align-items: flex-end; 
	justify-content: center;
}
.footer{
	height:350px;
	background-position-y:bottom; 
	display:flex; 
}
.footer-mobile{
	background-position-y:bottom; 
	background-position-x:center; 
	display:flex; 
}
.poppinsSemiboldFont{
	font-family: "Poppins-SemiBold";
}
.poppinsboldFont{
	font-family: "Poppins-Bold" !important;
}
.poppinsRegularFont{
	font-family: "Poppins-Regular";
}
.poppinsMediumFont{
	font-family: "Poppins-Medium";
}
.poppinsLightFont{
	font-family: "Poppins-Light";
}
.aboutLabel{
	color: #FEFEFE; 
	padding-bottom:400px; 
	font-size:185px; 
	margin-left: 0px;
}
.lusciousFont{
	font-family: "Luscious-Lifestyles-Script";
	font-weight: 100;
}
/* .colorGreen{
	color: #414A25;
}
.colorGrey{
	color: lightgray;
}
.colorBrown{
	color: #BEA67C;
} */
.h2{
	font-size:250px;
}
.h3{
	font-size:37px; 
	text-transform: uppercase;
}
.p{
	font-size:18px;
}
.buttons{
	font-size:15px;
	text-transform: uppercase;
	color:white;
	width:150px;;
	margin: 0px 5px;
    padding: 10px 0px;
}
.buttons-tablet{
	font-size:13px;
	text-transform: uppercase;
	color:white;
	width:100px;;
	margin: 0px 5px;
    padding: 10px 0px;
}
.buttons-mobile{
	font-size:11px;
	text-transform: uppercase;
	color:white;
	width:90px;;
	margin: 0px 5px;
    padding: 10px 0px;
}
.a{
	color:white;
	font-size:15px;
}
.a-tablet{
	color:white;
	font-size:13px;
}
.a-mobile{
	color:white;
	font-size:11px;
}
</style>